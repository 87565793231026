import eight from '../assets/svg/projects/eight.svg'
import five from '../assets/svg/projects/five.svg'
import four from '../assets/svg/projects/four.svg'
import one from '../assets/svg/projects/one.svg'
import seven from '../assets/svg/projects/seven.svg'
import six from '../assets/svg/projects/six.svg'
import three from '../assets/svg/projects/three.svg'
import two from '../assets/svg/projects/two.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Occy Recruitment Project - Client Portal',
        projectDesc: 'Discover occy for faster, easier and low cost hiring.',
        tags: ['React', 'Node', 'Typescript', 'SQL'],
        code: 'https://github.com/SmartRecruitOnline/OccyAPP',
        demo: 'https://app.occy.co.uk/',
        image: six
    },
    {
        id: 1,
        projectName: 'Occy Recruitment Project - Admin Portal',
        projectDesc: 'Discover occy for faster, easier and low cost hiring.',
        tags: ['React', 'Node', 'Typescript', 'SQL'],
        code: 'https://github.com/SmartRecruitOnline/OccyADMIN',
        demo: 'https://admin.occy.co.uk/',
        image: six
    },
    {
        id: 1,
        projectName: 'Occy Recruitment Project - Career Portal',
        projectDesc: 'Discover occy for faster, easier and low cost hiring.',
        tags: ['React', 'Node', 'Typescript', 'SQL'],
        code: 'https://github.com/SmartRecruitOnline/OccyCareers',
        image: six
    },
    {
        id: 2,
        projectName: 'SmartRecruitOnline - Client Portal',
        projectDesc: 'Recruitement - Client Portal',
        tags: ['Angularjs', 'Nodejs', 'MySQL'],
        code: 'https://github.com/SmartRecruitOnline/SmartAPP',
        demo: 'https://app.smartrecruitonline.com/',
        image: "" || seven
    },
    {
        id: 3,
        projectName: 'SmartRecruitOnline - Admin Portal',
        projectDesc: 'Recruitement -Admin Portal',
        tags: ['Angular2+', 'Nodejs', 'MySQL'],
        code: 'https://github.com/SmartRecruitOnline/SmartADMIN',
        demo: 'https://frontoffice2.smartrecruitonline.com/',
        image: eight
    },
    {
        id: 4,
        projectName: 'SmartRecruitOnline - Candidate Portal',
        projectDesc: 'Recruitement - Candidate Portal',
        tags: ['Angular2+', 'Nodejs', 'MySQL'],
        code: 'https://github.com/SmartRecruitOnline/SmartPortal',
        demo: 'https://portal.smartrecruitonline.com/',
        image: four
    },
    {
        id: 5,
        projectName: 'SmartRecruitOnline - OnBoarding Service',
        projectDesc: 'Recruitement - Onboarding',
        tags: ['Reactjs', 'Nodejs', 'MySQL'],
        code: 'https://github.com/SmartRecruitOnline/OnboardingAPP',
        demo: 'https://obapp.smartrecruitonline.com/',
        image: five
    },
    {
        id: 6,
        projectName: 'SmartRecruitOnline - FormService',
        projectDesc: 'Recruitement FormService',
        tags: ['ReactJs', 'Nodejs', 'MySQL'],
        code: 'https://github.com/SmartRecruitOnline/SmartAPP2',
        demo: 'https://beta.smartrecruitonline.com/v5/forms',
        image: one
    },
    {
        id: 8,
        projectName: 'SmartRecruitOnline - CareerPage',
        projectDesc: 'Recruitement - CareerPage',
        tags: ['Angular', 'Nodejs', 'MySQL'],
        code: 'https://github.com/SmartRecruitOnline/CareersPage',
        demo: 'https://careerspage.co.uk/',
        image: three
    },
    {
        id: 9,
        projectName: 'Pali Apps',
        projectDesc: 'Straightforward email security - PaliApps gives small and medium-sized businesses (SMEs) complete control over who can access their emails.',
        tags: ['Reactjs', 'Nodejs', 'MySQL'],
        code: 'https://github.com/theraaz/paliapps-corporate-portal',
        demo: 'https://paliapps.com/',
        image: three
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
/* eslint-disable */
import { contactsGreen } from './images'

export const theLightTheme = {
    type: 'light',
    primary: '#DBA745',
    quaternary: '#EFF3F4',// for left container
    quaternaryLight: '#F7F9F9',
    secondary: '#33383F', //for right container
    tertiary: '#B2D3D6',//Text
    buttonColor: '#0F1419',
    contactsimg: contactsGreen
}

export const theDarkTheme = {
    type: 'dark',
    primary: '#A5A5E9',
    quaternary: '#1E2732',
    quaternaryLight: '#8b98a5',
    secondary: '#15202B',
    tertiary: '#EFF3F4',
    buttonColor: '#8B98A5',
    contactsimg: contactsGreen
}

export const experienceData = [
    {
        id: 1,
        company: 'SyntecX',
        jobtitle: 'Internship-Front-End Developer',
        startYear: '2019',
        endYear: 'Nov 2019'
    },
    {
        id: 2,
        company: 'Mern It Solutions',
        jobtitle: 'Full Stack Developer',
        startYear: 'Nov 2020',
        endYear: 'Present'
    }
    
]